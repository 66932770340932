import React, { Fragment } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core"


// Components
import SEO from "../components/Seo"
import LayoutBase from "../components/Layout"
import PageHeader from "../components/PageHeader"


// 登録している Font Awesome Icon を取得する
const falSadTear = findIconDefinition({ prefix: "fal", iconName: "sad-tear" })
const falEnvelope = findIconDefinition({ prefix: "fal", iconName: "envelope" })


/**
 * Recruit Component
 *
 * @param location {any} -
 * @return React Element {*}
 */
interface IRecruitDefaultProps {
    location: any,
}

const Recruit = (props: IRecruitDefaultProps) => {
    const query = useStaticQuery(graphql`
        query {
            company_pageHeader_backgroundImage: file(relativePath: {eq: "recruit_pageHeader_backgroundImage.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 2500, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                    }
                }
            },
        }
    `)
    const pageId = "recruit"
    const pageTitle = "採用情報"
    const location = props.location
    const { crumbs } = useBreadcrumb({
        location: location,
        crumbLabel: pageTitle,
    })

    return (
        <Fragment>
            <SEO pageTitle={pageTitle} />
            <LayoutBase
                pageId={pageId}
                crumbs={crumbs}
                location={location}
            >
                <PageHeader
                    title={pageTitle}
                    backgroundImage={query.company_pageHeader_backgroundImage.childImageSharp.fluid}
                    indicators={true}
                />
                <div className="common-content-layout common-content-format">
                    <ApplicationRequirements className="common-content-layout__section" />
                    <Sales className="common-content-layout__section" />
                    <SalesAssistant className="common-content-layout__section" />
                    <Programmer className="common-content-layout__section" />
                    <HardwareEngineer className="common-content-layout__section" />
                    <Designer className="common-content-layout__section" />
                    <PartTime className="common-content-layout__section" />
                </div>
            </LayoutBase>
        </Fragment>
    )
}


/**
 * 問い合わせボタン
 *
 * @param {string} to - 遷移先のURL
 * @return {*} React Element
 */
interface IRecruitContactUsButton {
    to: string,
}

const RecruitContactUsButton = (props: IRecruitContactUsButton) => {
    return (
        <div className="common-text-align-right">
            <Link to={props.to} className="button common-button-style-icon">
                <FontAwesomeIcon className="common-button-style-icon__icon" icon={falEnvelope} size="2x" fixedWidth />
                <span className="common-button-style-icon__label">問い合わせる</span>
            </Link>
        </div>
    )
}


/**
 * 募集要項
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
interface IApplicationRequirementsDefaultProps {
    className?: string,
}

const ApplicationRequirements = (props: IApplicationRequirementsDefaultProps) => {
    return (
        <div className={`grid-container ${props.className}`}>
            <div id="applicationRequirements" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h1>募集要項</h1>
                    <table className="hover stack common-table">
                        <tbody>
                            <tr>
                                <th>勤務地</th>
                                <td>本社（滋賀県 大津市 瀬田大江町横谷1番5）</td>
                            </tr>
                            <tr>
                                <th>勤務時間</th>
                                <td>9:00 ～ 18:00</td>
                            </tr>
                            <tr>
                                <th>休日休暇</th>
                                <td>土日祝(会社カレンダーによる)年末年始、夏季</td>
                            </tr>
                            <tr>
                                <th>優遇・福利厚生</th>
                                <td>
                                    <ul className="common-list-marker-check-square">
                                        <li>交通費支給（規定有）</li>
                                        <li>社会保険完備</li>
                                        <li>賞与（年2回、6月・12月に会社及び個人の業績により支給します）</li>
                                        <li>給与（年1回、4月に会社実績や本人の評価により見直しを行います）</li>
                                        <li>車通勤可</li>
                                        <li>龍谷大学の食堂利用可</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th>年齢</th>
                                <td>不問</td>
                            </tr>
                            <tr>
                                <th>学歴</th>
                                <td>不問</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}


/**
 * 募集をしていない
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
interface IBeNotOpenForDefaultProps {
    className?: string,
}

const BeNotOpenFor = (props: IBeNotOpenForDefaultProps) => {
    return (
        <div className={`callout secondary ${props.className}`}>
            <div
                className="common-icon-circle-2x common-icon-color-solid-gray"
                style={{ marginRight: "1rem" }}
            >
                <FontAwesomeIcon icon={falSadTear} size="2x" fixedWidth />
            </div>
            <p className="common-text-flat-medium h5">現在、募集をおこなっていません。</p>
        </div>
    )
}


/**
 * 営業
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
interface ISalesDefaultProps {
    className?: string,
}

const Sales = (props: ISalesDefaultProps) => {
    return (
        <div className={`grid-container ${props.className}`}>
            <div id="sales" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h2>営業</h2>
                    <BeNotOpenFor />
                </div>
            </div>
        </div>
    )
}


/**
 * 営業補助
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
interface ISalesAssistantDefaultProps {
    className?: string,
}

const SalesAssistant = (props: ISalesAssistantDefaultProps) => {
    return (
        <div className={`grid-container ${props.className}`}>
            <div id="salesAssistant" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h2>営業補助</h2>
                    <BeNotOpenFor />
                </div>
            </div>
        </div>
    )
}


/**
 * プログラマー
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
interface IProgrammerDefaultProps {
    className?: string,
}

const Programmer = (props: IProgrammerDefaultProps) => {
    return (
        <div className={`grid-container ${props.className}`}>
            <div id="programmer" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h2>プログラマー</h2>
                    {/* <BeNotOpenFor/> */}
                    <table className="hover stack common-table">
                        <tbody>
                            <tr>
                                <th>給与</th>
                                <td>月給 21万円 ～ （経験・能力による ※固定残業代20時間を含む）</td>
                            </tr>
                            <tr>
                                <th>業務</th>
                                <td>
                                    組み込みソフトウェア開発
                                </td>
                            </tr>
                            <tr>
                                <th>必要スキル・経験</th>
                                <td>特になし</td>
                            </tr>
                            <tr>
                                <th>歓迎スキル・経験</th>
                                <td>
                                    <ul>
                                        <li>組み込みソフトウェア開発経験</li>
                                        <li>画像処理、画像認識の開発経験</li>
                                        <li>理工系学科（機械、電気、情報など）を卒業された方</li>
                                        <li>数学・物理が得意な方</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th>必要言語・レベル</th>
                                <td>特になし</td>
                            </tr>
                            <tr>
                                <th>歓迎言語・レベル</th>
                                <td>C/C++</td>
                            </tr>
                        </tbody>
                    </table>
                    <RecruitContactUsButton to="/contact/?c1=9100&c2=9104" />
                </div>
            </div>
        </div>
    )
}


/**
 * ハードウェアエンジニア
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
interface IHardwareEngineerDefaultProps {
    className?: string,
}

const HardwareEngineer = (props: IHardwareEngineerDefaultProps) => {
    return (
        <div className={`grid-container ${props.className}`}>
            <div id="hardwareEngineer" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h2>ハードウェアエンジニア</h2>
                    <BeNotOpenFor />
                </div>
            </div>
        </div>
    )
}


/**
 * デザイナー
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
interface IDesignerDefaultProps {
    className?: string,
}

const Designer = (props: IDesignerDefaultProps) => {
    return (
        <div className={`grid-container ${props.className}`}>
            <div id="designer" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h2>デザイナー</h2>
                    <BeNotOpenFor />
                </div>
            </div>
        </div>
    )
}


/**
 * アルバイト / パートタイマー
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
interface IPartTimeDefaultProps {
    className?: string,
}

const PartTime = (props: IPartTimeDefaultProps) => {
    return (
        <div className={`grid-container ${props.className}`}>
            <div id="partTime" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h2>アルバイト / パートタイマー</h2>
                    <BeNotOpenFor />
                    {/*
                    <h3>事務スタッフ</h3>
                    <table className="hover stack common-table">
                        <tbody>
                        <tr>
                            <th>給与</th>
                            <td>時給 1,000円 ～ （試用期間中は 900円）</td>
                        </tr>
                        <tr>
                            <th>勤務日数</th>
                            <td>週 3日 (1日5時間) 〜 ※勤務応相談</td>
                        </tr>
                        <tr>
                            <th>業務</th>
                            <td>主に電子部品の管理、整理をしていただきます。 <br/>細かい作業が得意な方を歓迎します。</td>
                        </tr>
                        </tbody>
                    </table>
                    <RecruitContactUsButton to="/contact/?c1=9100&c2=9107"/>
                    */}
                </div>
            </div>
        </div>
    )
}


export default Recruit
